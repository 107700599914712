<template>
  <ul class="meta-nav">
    <li>
      <a :href="loginUrl" target="_blank" class="meta-nav-link">
        <SpriteSymbol name="lock" />
        <span>{{ $texts('login', 'Anmelden') }}</span>
      </a>
    </li>

    <li>
      <!-- FAQ. -->
      <nuxt-link :to="faqLink" class="meta-nav-link">
        <SpriteSymbol name="question" class="fill-current" />
        <span>{{ $texts('faq', 'FAQ') }}</span>
      </nuxt-link>
    </li>

    <li>
      <!-- Search. -->
      <nuxt-link :to="{ name: 'search' }" class="meta-nav-link">
        <SpriteSymbol name="search" />
        <span>{{ $texts('search', 'Suchen') }}</span>
      </nuxt-link>
    </li>

    <li>
      <MetaNavLanguageSwitcher />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { PageLanguage } from '#language-negotiation/language'

const props = defineProps({
  language: {
    type: String as PropType<PageLanguage>,
    default: '',
  },
})

const faqLink = computed(() => `/${props.language}/faq`)
const loginUrl = computed(
  () =>
    `https://app.fphch.org/app/pages/login/login.jsf?lang=${props.language}`,
)
</script>

<style lang="postcss"></style>
