<template>
  <div class="max-lg:py-15">
    <div
      class="relative transition-all duration-250 ease-in-out flex flex-row lg:flex-col"
    >
      <button
        v-click-away="clickAway"
        :disabled="mappedLanguageLinks.length === 0"
        class="language-switch-toggle meta-nav-link mr-10 lg:mr-0 flex max-lg:pointer-events-none"
        @click="langSwitchDropdownOpen = !langSwitchDropdownOpen"
      >
        <span class="max-lg:text-green-alt">
          {{
            activeLink?.code[0].toUpperCase() + activeLink?.code.slice(1)
          }}</span
        >
      </button>

      <div
        :style="`--target-height-mobile: ${filteredLanguageLinks.length * 37}px`"
        :class="{
          'h-auto lg:h-0': !langSwitchDropdownOpen,
          'h-auto lg:h-[var(--target-height-mobile)]': langSwitchDropdownOpen,
        }"
        class="language-switch-content lg:dropdown-content lg:overflow-hidden lg:absolute lg:top-full lg:bg-white lg:shadow-dropdown z-50 lg:mt-10 lg:-right-15"
      >
        <div class="flex flex-row lg:flex-col">
          <component
            v-bind="link.attrs"
            :is="link.isLink ? 'a' : 'span'"
            v-for="link in filteredLanguageLinks"
            :key="link.code"
            class="meta-nav-link max-lg:px-10 max-lg:border-l border-l-gray-500 px-15 lg:py-10 w-full"
            :class="{
              'text-green-alt': link.active,
              '!text-gray-100 cursor-not-allowed': !link.isLink,
              'hover:text-green-alt language-switch-link':
                !link.active && link.isLink,
            }"
            :title="link.label"
          >
            <span v-if="isMobileMenu">
              {{ link.code[0].toUpperCase() + link.code.slice(1) }}
            </span>
            <span v-else>
              {{ link.label }}
            </span>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { directive as vClickAway } from 'vue3-click-away'
const ALL = ['de', 'fr', 'it']
const { isMobileMenu } = useViewport()

function getLanguageLabel(code: string) {
  if (code === 'de') {
    return 'Deutsch'
  } else if (code === 'fr') {
    return 'Français'
  } else if (code === 'it') {
    return 'Italiano'
  }

  return code
}

const router = useRouter()
const route = useRoute()
const event = useRequestEvent()

/**
 * Detect whether we are rendering inside an island request.
 */
const isIsland = computed(() => {
  if (!event) {
    return false
  }
  if (!event.path) {
    return false
  }
  return (
    event.path.includes('nuxt_island') || event.path.includes('islands-page')
  )
})

// The computed language links. For Nuxt internal routes we can just return the
// new language param, as vue-router will use the current route.
const languageLinks = useLanguageLinks()
const site = useCurrentSite()
const mappedLanguageLinks = computed(() => {
  // Italian is not available on the FPHCH site.
  return ALL.filter((code) => site.name !== 'fphch' || code !== 'it').map(
    (code) => {
      const link = languageLinks.value.find((v: any) => v.code === code)
      // First resolve the provided language link.
      const resolved = link ? router.resolve(link.to).fullPath : undefined

      // Use the resolved link and again resolve it, this time using the
      // current route query params.
      let href = resolved
        ? router.resolve({ path: resolved, query: route.query }).fullPath
        : undefined

      // We replace the href of the language link with a known key that can be
      // replaced dynamically by the island consumer.
      if (isIsland.value) {
        href = '$LANGUAGE_LINK_' + code.toUpperCase() + '$'
      }

      return {
        active: !!link?.active,
        code,
        label: getLanguageLabel(code),
        isLink: !!link,
        attrs: {
          href: href || undefined,
        },
      }
    },
  )
})

const activeLink = mappedLanguageLinks.value.find((link) => link.active)

// remove the activeLink from mappedLanguageLinks
const filteredLanguageLinks = computed(() => {
  return mappedLanguageLinks.value.filter((link) => {
    return link.code !== activeLink?.code
  })
})

const langSwitchDropdownOpen = ref(false)

const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>
